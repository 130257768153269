import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactModal from "react-modal";
import { Container, Row, Col } from "react-grid-system";
import { RobotoFontWrapper } from "./RobotoFont";
import { BlackBtn } from "./BlackBtn";
import {
  checkPublicDomain,
  ContactSalesApi,
  userTrack,
} from "../Common";
import { InputError } from "../PrivacyRequestForm";

// CSS Imports
import "./global.css";
import "./Popup.css";

const ContactBtnWrapper = styled.div`
  margin-top: 29px;
  display: flex;
  justify-content: center;
`;

const Label = styled.label`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  color: #454545;
  margin-bottom: 1px;
  margin-top: 30px;
`;

const InputField = styled.input`
  outline: none;
  margin-top: 10px;
  background-color: #ffffff;
  color: #454545;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  width: 301px;
  height: 21px;
  padding: 10px;
  border-radius: 2px;
  border: 0.74px solid #95959580;

  ::placeholder {
    color: #a8a8a8;
    font-size: 18px;
    font-weight: 300;
  }

  &:focus {
    border: 0.74px solid #ff7624;
  }
  @media screen and (max-width: 767px) {
    width: 245px;
  }
`;

const ImageWrapper = styled.div`
  cursor: pointer;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  outline: none;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 13px;
  top: 7px;

  @media (min-width: 320px) and (max-width: 480px) {
    right: -17px;
  }

  &:hover {
    background-color: #ffe9dc;
    svg path {
      fill: #454545;
      transition: fill 0.3s ease-in-out;
      outline: none;
    }
  }
`;

const HeaderWrapper = styled.div`
  background: #ffc47dc7;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

const Heading = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 40.5px;
  letter-spacing: 0.01em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: -30px;
  }
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #454545;
  margin-bottom: 0;
  margin-top: 13px;
`;

const Text = styled.p`
  width: 600px;
  height: 47px;
  color: #454545;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
`;

const FormWrapper = styled.div`
  width: 323px;
  margin: 0 auto;
  margin-top: 40px;
`;

const ErrorWrapper = styled.div`
  height: 20px; /* Adjust the height as needed */
`;

export default function Newsletter(props) {
  const [isOpen, setisOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [validateEmail, setValidateEmail] = useState(false);
  const [properEmail, setProperEmail] = useState(false);
  const [validatePublicDomain, setValidatePublicDomain] = useState(false);

  const handleClose = async () => {
    if (!props.forEarlyAccess) {
      await userTrack("", "Onclick  of Close in Ideapoke Pulse Newsletter", "Homepage");
    } else {
      await userTrack("", "Onclick  of Close in Early Access-AI Video Transcription", "Homepage");
    }
    props.onClose();
  };

  useEffect(() => {
    const trackUser = async () => {
      if (!props.forEarlyAccess) {
        await userTrack("", "Onclick of Subscribe Ideapoke Pulse Newsletter", "Homepage");
      } else {
        await userTrack("", "Onclick of Subscribe Early Access-AI Video Transcription", "Homepage");
      }
    };

    trackUser();
  }, [props.forEarlyAccess]);



  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const SaveGetInTouchDetails = async (text, message, identifiers) => {
    const obj = {
      emailid: email,
      requesttype: text ? text : "NEWSLETTERSUBSCRIBE",
      pagename: window.location.href,
    };

    try {
      const url = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/populatehomepageuserrequest.html";

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error("Error updating account data");
      }

      const updatedData = await response.text();

      if (updatedData == "success" || updatedData == "fail") {
        props.setSuccessBanner(true);
        props.setMessageForSucessBanner(message);

        setValidatePublicDomain(false);
        setValidateEmail(false);
        setProperEmail(false);
        setEmail("");

        ContactSalesApi(null, null, email, identifiers[0]);
        ContactSalesApi(null, null, email, identifiers[1]);
        props.onClose();
      }
    } catch (error) {
      console.error("Error updating account data:", error);
    }
  };

  const handleFormSubmit = async () => {
    userTrack(email, "Onclick of Submit", "RequestDemo Popup");

    if (!email.trim()) {
      setProperEmail(false);
      setValidateEmail(true);
      setValidatePublicDomain(false);
      return;
    }

    if (!isValidEmail(email)) {
      setValidateEmail(false);
      setProperEmail(true);
      setValidatePublicDomain(false);
      return;
    }
    if (checkPublicDomain(email)) {
      setValidatePublicDomain(true);
      setValidateEmail(false);
      setProperEmail(false);
      return;
    }
    if (!props.forEarlyAccess) {
      await userTrack("", "Onclick  of Subscribe in Ideapoke Pulse Newsletter", "Homepage");

      SaveGetInTouchDetails(
        undefined,
        "Thank you for subscribing to the Ideapoke Pulse Newsletter",
        ["JOINNEWSLATTERUSERMAIL", "JOINNEWSLATTERADMINMAIL"]
      );

    } else {
      await userTrack("", "Onclick  of Subscribe in Early Access-AI Video Transcription", "Homepage");

      SaveGetInTouchDetails(
        "Early Access-AI Video Transcription",
        "Thank you for joining the waitlist for the Ideapoke AI Video Transcription tool. You will be notified when we launch this tool.",
        ["EARLYACCESSUSERMAIL", "EARLYACCESSADMINMAIL"]
      );
    }
    setValidateEmail(false);
    setProperEmail(false);
    setValidatePublicDomain(false);
  };

  return (
    <div>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={() => handleClose()}
        ariaHideApp={false}
        className="modal-popup-newsletter"
      >
        <RobotoFontWrapper>
          <HeaderWrapper>
            <Container>
              <Row>
                <Col xl={9} lg={9} sm={9} xs={9}>
                  <Heading>{props.mainHeading}</Heading>
                </Col>

                <Col xl={3} lg={3} sm={3} xs={3}>
                  <ImageWrapper onClick={() => handleClose()}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.20252 22.1668L0 20.9228L9.51144 11.0834L0 1.24398L1.20252 0L10.714 9.83942L20.2254 0L21.4279 1.24398L11.9165 11.0834L21.4279 20.9228L20.2254 22.1668L10.714 12.3274L1.20252 22.1668Z"
                        fill="#454545"
                      />
                    </svg>
                  </ImageWrapper>
                </Col>
              </Row>
            </Container>
          </HeaderWrapper>

          <div>
            <Container fluid>
              <Row>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <Title>{props.forEarlyAccess ? "" : "Ideapoke Pulse"}</Title>
                </Col>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <Text>
                    {props.forEarlyAccess
                      ? "Join our waitlist and get early access to our AI - Video Transcription tool"
                      : `We are a rapidly growing community. Join thousands of Research, Strategy & CVC professionals the world over that are identifying signals of change early on`}
                  </Text>
                </Col>
              </Row>
            </Container>
          </div>

          <div>
            <Container fluid>
              <Row>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <FormWrapper>
                    <Label>
                      Enter email address
                      <span style={{ color: "red" }}> *</span>
                    </Label>
                    <InputField
                      placeholder="Enter your work Email ID"
                      onChange={(e) => setEmail(e.target.value)}
                    ></InputField>
                    <ErrorWrapper>
                      {validateEmail && (
                        <InputError>Email is Required.</InputError>
                      )}
                      {properEmail && (
                        <InputError>Please enter valid Email.</InputError>
                      )}
                      {validatePublicDomain && (
                        <InputError>Please enter work Email.</InputError>
                      )}
                    </ErrorWrapper>
                  </FormWrapper>
                </Col>
                <Col xl={12} lg={12} sm={12} xs={12}>
                  <ContactBtnWrapper>
                    <BlackBtn onClick={handleFormSubmit}>Subscribe</BlackBtn>
                  </ContactBtnWrapper>
                </Col>
              </Row>
            </Container>
          </div>
        </RobotoFontWrapper>
      </ReactModal>
    </div>
  );
}

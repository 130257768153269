/* eslint-disable react/no-danger-with-children */
import styled from "styled-components";

// import React from 'react'

const Para = styled.p`
  font-family: ${(props) => ("Roboto")};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight?props.fontWeight:"400"};
  font-size: ${(props) => props.fontSize?props.fontSize:"12px"};
  color: ${(props) => (props.color ? props.color : "black")};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  padding: ${(props) => props.padding};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  margin-bottom: 0;
  margin-right: 0;
  margin-left: ${(props) => props.marginLeft};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  text-align: ${(props) => props.textAlign};
  letter-spacing: ${(props) => props.letterSpacing?props.letterSpacing:"0.6px"};
  text-transform: ${(props) => props.textTransform};
  text-decoration: ${(props) => props.textDecoration};
  line-height: ${(props) => props.lineHeight};

  &:hover {
    color: ${(props) => (props.hovercolor ? props.hovercolor : props.color)};
  }
  @media screen and (max-width: 1280px) {
    font-size: ${(props) => props.LgFontSize};
  }
  @media screen and (max-width: 767px) {
    font-size: ${(props) => props.mobFontSize};
    line-height: ${(props) => props.mobLineHeight};
    margin-top: ${(props) => props.mobMarginTop};
    letter-spacing: ${(props) => props.mobLetterSpacing};
    width: ${(props) => props.mobwidth};
    text-align: ${(props) => props.MobtextAlign};
    margin-bottom: ${(props) => props.marginbottom};
    position: ${(props) => props.mobPosition};
    right: ${(props) => props.mobRight};
    margin-left: ${(props) => props.mobMarginLeft};
  }
`;


export const P = ({
  dangerouslySetInnerHTML,
  onClick,
  textTransform,
  lineHeight,
  mobMarginBottom,
  margin,
  mobPosition,
  LgFontSize,
  fontFamily,
  mobRight,
  fontWeight,
  mobwidth,
  mobMarginLeft,
  mobLetterSpacing,
  mobMarginTop,
  moblineHeight,
  letterSpacing,
  cursor,
  textAlign,
  marginLeft,
  MobtextAlign,
  fontSize, 
  mobFontSize, 
  mobLineHeight,
  width,
  color,
  hoverColor, 
  padding,
  textDecoration,
  marginTop,
  text
}) => {

  return <Para 
   dangerouslySetInnerHTML={dangerouslySetInnerHTML} 
   onClick={onClick}
    textTransform={textTransform} 
    margin={margin}
    lineHeight={lineHeight} 
    mobPosition={mobPosition} 
    LgFontSize={LgFontSize}
    fontFamily={fontFamily}
    marginbottom={mobMarginBottom}
    mobRight={mobRight}
    fontWeight={fontWeight} 
    mobwidth={mobwidth} 
    mobMarginLeft={mobMarginLeft} 
    mobLetterSpacing={mobLetterSpacing} 
    mobMarginTop={mobMarginTop} 
    moblineHeight={moblineHeight} 
    letterSpacing={letterSpacing} 
    cursor={cursor} 
    textAlign={textAlign} 
    marginLeft={marginLeft} 
    MobtextAlign={MobtextAlign} 
    fontSize={fontSize} 
    mobFontSize={mobFontSize} 
    mobLineHeight={mobLineHeight} 
    width={width} 
    color={color} 
    hovercolor={hoverColor} 
    padding={padding} 
    textDecoration={textDecoration} 
    marginTop={marginTop}>{text}
    </Para>

}
import React, { useState } from "react";
import styled from "styled-components";
import { P } from "./CommonStyle";
import CheckBox from "./CheckBox";
import Button from "./Button";
import { checkPublicDomain } from "./Common";

const Topwraper = styled.div`
  margin: 0 auto;
  width: 900px;
`;

const Inputwraper = styled.div`
`;

const InputText = styled.input`
  border-radius: 8px;
  border: 1px solid rgba(149, 149, 149, 0.5);
  background: var(--base-colours-light, #fff);
  width: 375px;
  height: 50px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-family: Roboto;
  resize: none;

  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #a8a8a8;
  }
  &:focus {
    outline: none;
    border: 1px solid #ff7624;
  }
  @media screen and (max-width: 767px) {
    width: 300px;
  }
`;
const Outerwrap = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  justify-content: center;
  align-items: inherit;
`;
const Textarea = styled.textarea`
  border-radius: 8px;
  border: 1px solid rgba(149, 149, 149, 0.5);
  background: var(--base-colours-light, #fff);
  width: 380px;
  height: 148px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  font-family: Roboto;
  resize: none;


  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #a8a8a8;
  }
  &:focus {
    outline: none;
    border: 1px solid #ff7624;
  }
  @media screen and (max-width: 767px) {
    width: 300px;
  }
`;
const Outerdownload = styled.div`
  margin: 50px 0 0 38px;
`;
const CheckVaue = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 12px;
`;
const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 25px;
  margin-bottom: 40px;
  justify-content: flex-start;
  @media screen and (max-width: 767px) {
    margin-right: 20px;
  }
`;

const Textwrap = styled.div`
  color: #000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  letter-spacing: 1.2px;
  margin-left: 45px;

  @media screen and (max-width: 767px) {
    width: 100%;
    row-gap: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 7pxpx;
    font-size: 18px;
  }
`;
const Para = styled.p`
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
  margin-left: 45px;
`;
const Additionalwraper = styled.div`
  margin-top: 35px;
  margin-left: 3px;
`;

export const InputError = styled.div`
  font-size: 14px;
  font-family: "Roboto";
  line-height: 21px;
  color: #ff4141;
  text-align: left;
  /* margin-bottom: 12px; */
  
`;

const Boxwrap = styled.div`
display: flex;
justify-content: space-between;
`
const Boxonewrap = styled.div`
display: flex;
 justify-content: space-between;
 gap:78px;
  -webkit-box-pack: justify;
  flex-direction: column;
  row-gap: 30px;
`
const Texboxtarea = styled.div`
margin-top: -170px;
`

export default function PrivacyRequestForm(props) {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmailid] = useState("");
  const [isdelete, setIsdelete] = useState(false);
  const [isdownload, setIsdownload] = useState(false);
  const [additionalinfo, setAdditionalinfo] = useState("");
  const [typeofredetails, settypeofredetails] = useState("");
  const [validateFirstName, setValidateFirstName] = useState(false);
  const [validateFirstNameReq, setValidateFirstNameReq] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [properEmail, setProperEmail] = useState(false);
  const [validatePublicDomain, setValidatePublicDomain] = useState(false);

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const validateForm = async (e) => {
    e.preventDefault();
    setValidateFirstNameReq(false);
    setValidateFirstName(false);
    setValidateEmail(false);
    setProperEmail(false);
    setValidatePublicDomain(false);

    const isNameValid = firstname.trim().length >= 2;
    // Check if firstname has a length of 0
    const isFrstNameValid = firstname.trim().length === 0;
    const isEmailValid = isValidEmail(email);

    if (isFrstNameValid) {
      // Trigger these validations if firstname has length 0
      setValidateFirstNameReq(true);
      setValidateFirstName(false);
    } else {
      // Otherwise, validate the name length
      if (!isNameValid) {
        setValidateFirstName(true);
        setValidateFirstNameReq(false);
      }
    }
    if (!email.trim()) {
      setValidateEmail(true);
    } else if (!isEmailValid) {
      setProperEmail(true);
    } else if (checkPublicDomain(email)) {
      setValidatePublicDomain(true);
      return;
    }

    if (isNameValid && isEmailValid) {
      await savePrivacyDetails();
    }
  };

  const savePrivacyDetails = async () => {
    try {
      const obj = {
        firstname: firstname,
        lastname: lastname,
        emailid: email,
        isdelete: isdelete ? 1 : 0,
        isdownload: isdownload ? 1 : 0,
        additionalinfo: additionalinfo,
        typeofredetails: typeofredetails,
      };

      const url = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/populateprivacypageinfo.html";
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });

      if (!response.ok) {
        throw new Error("Error updating account data");
      }

      const updatedData = await response.text();
      props.setSuccessBanner(true)
    } catch (error) {
      console.error("Error updating account data:", error);
    }
  };


  return (
    <div>
      <Topwraper>
        <Textwrap>Privacy Request Form</Textwrap>
        <Para>
          Use this form to make a request regarding your data under the
          'California Consumer Privacy Act (CCPA)'. The different types of
          requests you can make are found below. Enter your details, choose the
          type of request you would like to make, and submit the form.
        </Para>
        <Textwrap>Your details</Textwrap>
        <Para>
          We need your name and email address in order to process your request
          and safeguard your data. We will use the data gathered here only to
          process your request and for record-keeping as required by 'CCPA'.
        </Para>
        <Para>
          You will receive an email to verify your address. Follow the
          instructions you will receive to complete processing of your request.
        </Para>
        <Outerwrap>
          <Boxonewrap>

            <Inputwraper>
              <InputText
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="First Name"
              ></InputText>
              {validateFirstNameReq && (
                <InputError>First name is required.</InputError>
              )}
              {validateFirstName && (
                <InputError>Name should have minimum 2 characters.</InputError>
              )}
            </Inputwraper>

            <Inputwraper>
              <InputText
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Last Name"
              ></InputText>
            </Inputwraper>
          </Boxonewrap>
          <Boxwrap>


            <Inputwraper>
              <InputText
                onChange={(e) => setEmailid(e.target.value)}
                placeholder="Email Address"
              ></InputText>
              {validateEmail && <InputError>Email is Required.</InputError>}
              {properEmail && <InputError>Please enter valid Email.</InputError>}
              {validatePublicDomain && (
                <InputError>Please enter work Email.</InputError>
              )}
            </Inputwraper>
            <Inputwraper>
              <Texboxtarea>
                <Textarea
                  onChange={(e) => settypeofredetails(e.target.value)}
                  placeholder="Type of Request"
                ></Textarea>
              </Texboxtarea>

            </Inputwraper>
          </Boxwrap>

        </Outerwrap>

        <Outerdownload>
          <P
            text="Download"
            color="#000"
            fontSize="18px"
            fontWeight="600"
            letterSpacing="2.4px"
          ></P>

          <CheckVaue>
            <CheckBox
              checked={isdownload === 1}
              onChange={(e) => setIsdownload(e.target.checked ? 1 : 0)}
            ></CheckBox>
            <P
              text="We will provide a complete report of your personal data across our systems"
              color="#454545"
              fontSize="16px"
              fontWeight="400"
              cursor="auto"
            ></P>
          </CheckVaue>

          <P
            text="Delete"
            color="#000"
            fontSize="18px"
            fontWeight="600"
            letterSpacing="2.4px"
          ></P>

          <CheckVaue>
            <CheckBox
              checked={isdelete === 1}
              onChange={(e) => setIsdelete(e.target.checked ? 1 : 0)}
            ></CheckBox>
            <P
              text="Delete your personal data found in our systems"
              color="#454545"
              fontSize="16px"
              fontWeight="400"
              cursor="auto"
            ></P>
          </CheckVaue>
          <Inputwraper>
            <Additionalwraper>
              <P
                text="Additional information"
                color="#454545"
                fontSize="18px"
                fontWeight="600"
                cursor="auto"
              ></P>
            </Additionalwraper>

            <Textarea
              onChange={(e) => setAdditionalinfo(e.target.value)}
              placeholder="Tell us more about your request here"
            ></Textarea>
          </Inputwraper>
          <BtnWrap>
            <Button
              onClick={validateForm}
              text="Submit"
              variant="solidOrange"
              width="130px"
              height="50px"
            ></Button>
          </BtnWrap>
        </Outerdownload>
      </Topwraper>
    </div>
  );
}

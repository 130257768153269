//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
export const OrangeOutlineBtn = styled.button`
  font-family: Roboto;
  width: ${({ width }) => width || "234px"};
  height: ${({ height }) => height || "56px"};
  padding: 14px 10px 14px 10px;
  border-radius: 100px;
  border: 0.78px solid #ff7624;
  background-color: #fef4ea;
  color: var(--text-color-pumpkin);
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: var(--lh23);
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, border 0.3s ease;

  &:hover {
    background-color: #ffebd8;
    border: 1px solid #ff7624;
  }
`;

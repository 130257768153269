import React, { useEffect, useState } from "react";
import checkTick from "./images/checkTick.svg";
import styled from "styled-components";

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  /* Style the checkbox */

  appearance: none;

  background: #ff7624;

  border: none;

  border-radius: 3px;

  box-sizing: border-box;

  cursor: pointer;

  height: 20px;

  margin: 0;

  outline: none;

  padding: 0;

  position: relative;

  width: 20px;
  top: 2px;
  display: block !important;

  &:before {
    content: "";

    position: absolute;

    top: 1px;

    left: 1px;

    width: 18px;

    height: 18px;

    background: #fff;

    border-radius: 2px;

    transition: all 0.2s;
  }

  &:checked:before {
    background: #ff7624;
  }

  &:checked:after {
    content: url(${checkTick.src});

    position: absolute;

    top: -1px;

    left: 3px;

    color: #fff;

    font-size: 16px;
  }
`;

export default function CheckBox(props) {
  return (
    <>
      <label>
        <Checkbox onChange={props.onChange}  value={props.value}  name={props.name} id={props.id}
          checked={props.checked} />
      </label>
    </>
  );
}

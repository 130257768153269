import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Image from "next/image";
import { useRouter } from 'next/router';

import "./Menu.css";
import SWOTIMG from "../images/SWOTIMG.svg";
import BlueTick from "../images/BlueTick.svg";
import OrangeStarSmall from "../images/OrangeStarSmall.svg";
import { P } from "../CommonStyle";
import BarIcon from "../images/bars-solid.svg";
import Add from "../images/plus.png";
import Logo1 from "../images/Logo1.svg";
import { userTrack } from "../Common";
import { BlackBtn } from "../IPWebsiteRedesign/BlackBtn";
import { OrangeOutlineBtn } from "../IPWebsiteRedesign/OrangeOutlineBtn";
import Newsletter from "../IPWebsiteRedesign/NewsLetter";
import { Container, Row, Col } from "react-grid-system";

import { RobotoFontWrapper } from "../IPWebsiteRedesign/RobotoFont";
import getConfig from "next/config";

const ExtenedBlackBtn = styled(BlackBtn)`
font-size: 14px !important;
`;

const ExtenedOrangeOutlineBtn = styled(OrangeOutlineBtn)`
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
`;

const ThreeBtnWrapper = styled.div`
display: flex;
gap: 10px;
position: relative;
left: 13px;
`;


const MenuWrap = styled.div`
  border-radius: 0px;
  background: #fdfdfd;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 100px;
  margin: 0 auto;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  @media screen and (max-width: 1280px) {
    gap: 40px;
  }
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
  }
`;
const HeaderOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const MenuTxt = styled.p`
  margin: 0;
  -webkit-text-stroke-color: #454545;
  -webkit-text-stroke-width: 0.01px;
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  -webkit-font-smoothing: antialiased;

  :hover {
    -webkit-text-stroke-width: 0.3px;
    cursor: pointer;
  }
  @media screen and (max-width: 1280px) {
    padding: 16px 8px;
  }
`;
const TrialBtn = styled.button`
  padding: 16px 12px;
  border-radius: 5px;
  background: #ff7624;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f5f5f5;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  outline: none;
  border: none;
  cursor: pointer;
  margin-left: 16px;

  :hover {
    background-color: #cd5900;
  }
`;
const PlfrmDrpdwn = styled.div`
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  padding: 35px 55px;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  width: 794px;
  left: -285px;
  top: 62px;
`;
const Svg = styled.svg`
  position: absolute;
  top: -14px;
  left: ${(props) => (props.isSolution ? "232px" : "300px")};
`;

const DrpContent = styled.div`
  display: flex;
  gap: 100px;
`;
const DropLeftMenu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;
const PTitle = styled.p`
  margin: 0;
  color: #000;
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-wrap: nowrap;
`;
const PSubTxt = styled.p`
  margin: 0;
  color: #646464;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 4px;
`;
const MenuLWrap = styled.div`
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #f3e4d2;
  }
`;
const TempTitle = styled.p`
  margin: 0;
  margin-bottom: 20px;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.09px;
  text-align: left;
  color: #515151;
`;
const TempImg = styled.div`
  width: 396px;
  height: 255px;
  border-radius: 5px;
  background: #f2f8fe;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DescTemp = styled.p`
  margin: 0;
  color: #646464;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 25px;
  margin-bottom: 10px;
`;
const ViewTemp = styled.a`
  color: #4b59dd;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;

  :hover {
    color: #15436d;
  }
`;
const SolDrpdwn = styled.div`
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  padding: 35px 40px;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  width: 455px;
  left: -212px;
  top: 62px;
`;
const ResouDrpdwn = styled.div`
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  width: 815px;
  left: -282px;
  top: 62px;
  padding: 15px;
`;
const SolContent = styled.div`
`;
const SolDrpHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 25px;
`;
const SolDrpTxt = styled.p`
  margin: 0;
  color: #000;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const ResearchWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;
`;
const WrapedTxt = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 250px;
`;
const HoverTxt = styled.div`
  padding: 2px 8px;

  &:hover {
    border-radius: 5px;
    background: #f3e4d2;
  }
`;
const MenuWrp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 20px;
  height: 25px;
  &:hover {
    background-color: #f3e4d2;
    cursor: pointer;
  }

  @media screen and (max-width: 1280px) {
    padding: 0px 11px;
  }
`;

const NewSubMenuText = styled.div`
display: flex;
gap: 10px;
margin-bottom: 22px;
`;

const NewSubMenuLinkText = styled.div`
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 12px;
text-align: left;
color: #000000;
position: relative;
cursor: pointer;

&:hover {
  color: #ff7624;
}
`;


const Box = styled.div`
margin-top: 0px;
`;


const GrowthTitleICS = styled.p`
font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 16px;
text-align: left;
color: #000000;
position: relative;
padding: 10px;
text-wrap: nowrap;
cursor: pointer;

&:hover {
  background-color: #f3e4d2;
}
`;

const GrowthTitle1 = styled.p`
font-family: Roboto;
font-size: 18px;
font-weight: 400;
line-height: 16px;
text-align: left;
color: #000000;
position: relative;
padding: 10px;
text-wrap: nowrap;
`;

const TickTitle = styled.div`
display: flex;
gap: 5px;
margin-left: 10px;
align-items: center;
`;

const GrowthTitle2 = styled.div`
font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16px;
text-align: left;
color: #000000;
position: relative;
padding: 10px;
padding-left: 2px;
cursor: pointer;

&:hover {
  background-color: #f3e4d2;
}
`;

const FixedWidthBox = styled.div`
margin-left: 33px;
width: 215px;
`;


export default function MenuHeader(props) {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const [RDP, setRDP] = useState(false);

  const router = useRouter();
  const { publicRuntimeConfig } = getConfig();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsVisible(false);
        setIsSolution(false);
        setIsGla(false);
        setGla(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleVisibility = () => {
    userTrack("", "Onclick of Product in header", "HomePage Header");
    setIsVisible(!isVisible);
    setIsSolution(false);
    setIsGla(false);
    setGla(false);
    setDemo(false);
  };



  const [isGla, setIsGla] = useState(false);

  const [gla, setGla] = useState(false);
  const toggleVisibilityGla = () => {
    userTrack("", "Onclick of Resource in header", "HomePage Header");
    setIsGla(!isGla);
    setIsVisible(false);
    setIsSolution(false);
    setGla(true);
    setDemo(false);
  };

  const [isSolution, setIsSolution] = useState(false);

  const toggleSolution = () => {
    userTrack("", "Onclick of Solution in header", "HomePage Header");
    setIsSolution(!isSolution);
    setIsVisible(false);
    setIsGla(false);
    setGla(false);
    setDemo(false);
  };

  const [demo, setDemo] = useState(false);



  const HandleLogin = async () => {
    await userTrack("", "Onclick of Login in header", "HomePage Header");
    window.location.href = process.env.NODE_ENV == "development" ? "/signin" : publicRuntimeConfig.assetPrefix + "/signin.html";
  };


  const [contactus, setContactUs] = useState(false);

  const AboutUs = async () => {
    await userTrack("", "Onclick of AboutUs in header", "HomePage Header");
    window.location.href = process.env.NODE_ENV == "development" ? "/aboutus" : publicRuntimeConfig.assetPrefix + "/aboutus.html";
  };

  const HandleReports = async () => {
    await userTrack("", "Onclick of GLA Reports in header", "Homepage");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/growthleader/type/reports";
  };

  const HandleArticle = async () => {
    await userTrack("", "Onclick of GLA Article in header", "Homepage");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/growthleader/type/articles";
  };

  const HandleWhitePaper = async () => {
    await userTrack("", "Onclick of GLA WhitePaper in header", "Homepage");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/growthleader/type/white-papers";
  };
  const HandleInteractiveCustomerStories = async () => {
    await userTrack(
      "",
      "Onclick of InteractiveCustomerStories in header",
      "Homepage"
    );
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + process.env.NODE_ENV == "development" ? "/interactivestories" : publicRuntimeConfig.assetPrefix + "/interactivestories.html";

  };

  const HandleCaseStudy = async () => {
    await userTrack("", "Onclick of GLA CaseStudy in header", "Homepage");
    window.location.href =
      process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/growthleader/type/case-studies";
  };


  const HandleResearchAssistantClick = async () => {
    await userTrack("", "Onclick of Research Assistant in header", "Header");
    window.location.href = process.env.NODE_ENV == "development" ? "/Research-Assistant" : publicRuntimeConfig.assetPrefix + "/Research-Assistant.html";
  };

  const HandleGoToResearchTools = async (text) => {
    if (text == "Summarization") {
      await userTrack("", "Onclick of AI Summarization in header", "Header");
      localStorage.setItem("Summarization", true);
      window.location.href = process.env.NODE_ENV == "development" ? "/AI-Research-Tools" : publicRuntimeConfig.assetPrefix + "/AI-Research-Tools.html";
    } else if (text == "Search") {
      await userTrack("", "Onclick of AI Company Search in header", "Header");
      localStorage.setItem("Search", true);
      window.location.href = process.env.NODE_ENV == "development" ? "/AI-Research-Tools" : publicRuntimeConfig.assetPrefix + "/AI-Research-Tools.html";
    } else if (text == "Transcription") {
      await userTrack(
        "",
        "Onclick of AI Video Transcription in header",
        "Header"
      );
      localStorage.setItem("Transcription", true);
      window.location.href = process.env.NODE_ENV == "development" ? "/AI-Research-Tools" : publicRuntimeConfig.assetPrefix + "/AI-Research-Tools.html";
    }
  };

  const HandleResearchEliteClick = async () => {
    await userTrack("", "Onclick of Research Elite in header", "Header");
    window.location.href = process.env.NODE_ENV == "development" ? "/Research-Elite" : publicRuntimeConfig.assetPrefix + "/Research-Elite.html";
  };

  const HandleResearchAIToolsClick = async () => {
    await userTrack("", "Onclick of AI Research Tools in header", "Header");
    window.location.href = process.env.NODE_ENV == "development" ? "/AI-Research-Tools" : publicRuntimeConfig.assetPrefix + "/AI-Research-Tools.html";
  };

  const HandleResearchDesignPlatform = async () => {
    await userTrack("", "Onclick of Research Design Platform in header", "");


    window.location.href = process.env.NODE_ENV == "development" ? "/AI-Business-Research-Platform" : publicRuntimeConfig.assetPrefix + "/AI-Business-Research-Platform.html";
    setRDP(true);
  };

  const HandleResearchTemplateClick = async () => {
    await userTrack("", "Onclick of View all Templates in header", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/Research-Templates" : publicRuntimeConfig.assetPrefix + "/Research-Templates.html";
  };

  const HandleBackLogo = async () => {
    await userTrack("", "Onclick of IpLogo in header", "HomePage");
    router.push({
      pathname: `/`,
    });
  };

  const handleMarketAnalysis = async () => {
    await userTrack("", "Onclick of Market Analysis in header", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/Market-Analysis" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/Market-Analysis.html";
  };

  const ceseve = async () => {
    await userTrack("", "Onclick of CES PaidReport in header", "HomePage");
    localStorage.setItem("ScrolltoPrice", "false");
    window.location.href = process.env.NEXT_PUBLIC_SERVER_NAME_URL + "/CES2024Reports.html";
  };

  const ceseves = async () => {
    await userTrack("", "Onclick of SXSW in header", "HomePage");
    window.open("https://get.ideapoke.com/sxsw2024/", "_blank");
  };

  const cersac = async () => {
    await userTrack("", "Onclick of RSAC in header", "HomePage");
    window.open("https://get.ideapoke.com/rsac2024/", "_blank");
  };

  const handleTechnologyAnalysis = async () => {
    await userTrack("", "Onclick of Technology Analysis in header", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/Technology-Analysis" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/Technology-Analysis.html";
  };

  const handleNewBusinessCreation = async () => {
    await userTrack(
      "",
      "Onclick of New Business Creation in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/New-Business-Creation" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/New-Business-Creation.html";
  };

  const handleNewProductDevelopment = async () => {
    await userTrack(
      "",
      "Onclick of New Product Development in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/New-Product-Development" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/New-Product-Development.html";
  };

  const handleApplicationUseCaseAnalysis = async () => {
    await userTrack(
      "",
      "Onclick of Application UseCase Analysis in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/ResearchGoal/Use-Case-Analysis" : publicRuntimeConfig.assetPrefix + "/ResearchGoal/Use-Case-Analysis.html";
  };

  const HandleCorporate = async () => {
    await userTrack(
      "",
      "Onclick of Corporate and Strategy  in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/Corporate-Strategy" : publicRuntimeConfig.assetPrefix + "/Team/Corporate-Strategy.html";
  };

  const HandleRandD = async () => {
    await userTrack(
      "",
      "Onclick of R&D and Technology Scouting in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/R&D-and-Tech-Scouting" : publicRuntimeConfig.assetPrefix + "/Team/R&D-and-Tech-Scouting.html";
  };

  const HandleSales = async () => {
    await userTrack("", "Onclick of Sales and Marketing in header", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/Sales-and-Marketing" : publicRuntimeConfig.assetPrefix + "/Team/Sales-and-Marketing.html";
  };

  const HandleCVC = async () => {
    await userTrack("", "Onclick of CVC and M&A in header", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/CVC-and-M&A" : publicRuntimeConfig.assetPrefix + "/Team/CVC-and-M&A.html";
  };

  const HandleNewBusiness = async () => {
    await userTrack(
      "",
      "Onclick of New Business Development in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Team/New-Business-Development" : publicRuntimeConfig.assetPrefix + "/Team/New-Business-Development.html";
  };

  const HandleGoToDashboard = async () => {
    await userTrack("", "Onclick of go to dashboard in header", "HomePage");
    window.location.href = "/design/dashboard.html";
  };
  const closeModal = () => setModalOpen(false);



  useEffect(() => {
    setTimeout(() => {
      if (process.env.NEXT_PUBLIC_SERVER_NAME_URL.includes("ideapoke.com")) {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=UA-23050664-1";
        script.async = true;
        script.strategy = "afterInteractive"
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }, 3000);
  }, []);

  const url = router.pathname//window.location.href;

  const ContactSales = async () => {
    await userTrack("", "Onclick of ContactSales in header", "HomePage");
    window.location.href = process.env.NODE_ENV == "development" ? "/Contact-Sales" : publicRuntimeConfig.assetPrefix + "/Contact-Sales.html";
  };

  const HandleFreeTrail = async () => {
    await userTrack(
      "",
      "Onclick of Get started for free in header",
      "HomePage"
    );
    window.location.href = process.env.NODE_ENV == "development" ? "/Get-Started" : publicRuntimeConfig.assetPrefix + "/Get-Started.html";
  };


  return (
    <div>
      <RobotoFontWrapper>

        <MenuWrap ref={dropdownRef}>
          <div>
            <Image alt="Ideapoke:Logo" width={200} height={50} onClick={HandleBackLogo} src={Logo1} style={{ cursor: "pointer" }} className="mob-logo-fix"></Image>
          </div>
          <div>
            <HeaderOption>
              <div style={{ position: "relative" }}>
                <MenuWrp
                  className={isVisible ? "submene-active" : ""}
                  onClick={toggleVisibility}
                >
                  {/* Home */}

                  <MenuTxt>Product</MenuTxt>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 -25 110 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M102.365 1.30035L54.9952 47.5257L7.62546 1.30035C6.77912 0.472792 5.64298 0.00948728 4.4599 0.00948728C3.27682 0.00948728 2.14068 0.472792 1.29434 1.30035C0.884543 1.70231 0.558984 2.18207 0.336731 2.71156C0.114478 3.24105 0 3.80961 0 4.38394C0 4.95828 0.114478 5.52684 0.336731 6.05632C0.558984 6.58581 0.884543 7.06558 1.29434 7.46753L51.6874 56.6532C52.5724 57.5167 53.7593 58 54.9952 58C56.231 58 57.4179 57.5167 58.3029 56.6532L108.696 7.47702C109.109 7.07477 109.437 6.59382 109.661 6.06258C109.885 5.53134 110 4.96056 110 4.38394C110 3.80732 109.885 3.23654 109.661 2.7053C109.437 2.17406 109.109 1.69312 108.696 1.29086C107.85 0.4633 106.713 0 105.53 0C104.347 0 103.211 0.4633 102.365 1.29086V1.30035Z"
                        fill="#606060"
                      />
                    </svg>
                  </div>
                </MenuWrp>
                {/* Platform */}
                {isVisible && (
                  <PlfrmDrpdwn>
                    <Svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="78"
                      height="40"
                      viewBox="0 0 78 40"
                      fill="none"
                    >
                      <path
                        d="M39.1772 0L77.6772 40H0.677246L39.1772 0Z"
                        fill="white"
                      />
                    </Svg>
                    <DrpContent>
                      <div>
                        <DropLeftMenu>
                          <MenuLWrap onClick={HandleResearchDesignPlatform}>
                            <PTitle>Ideapoke Business Research Platform</PTitle>
                            <PSubTxt>A Smarter way to Research.</PSubTxt>
                          </MenuLWrap>
                          <MenuLWrap onClick={HandleResearchAssistantClick}>
                            <PTitle>
                              Research <strong>Assistant</strong>
                            </PTitle>
                            <PSubTxt>Get AI to work for you!</PSubTxt>
                          </MenuLWrap>
                          <MenuLWrap onClick={HandleResearchEliteClick}>
                            <PTitle>
                              Research <strong>Elite</strong>
                            </PTitle>
                            <PSubTxt>
                              Powerful AI Data. Curated Insights for Planning.
                            </PSubTxt>
                          </MenuLWrap>
                          <MenuLWrap onClick={HandleResearchAIToolsClick}>
                            <PTitle>
                              <strong>AI</strong> Research Tools
                            </PTitle>
                            <PSubTxt>Summarize. Query. Get Answers.</PSubTxt>
                          </MenuLWrap>

                          <div>
                            <NewSubMenuText>
                              <Image alt="Icon" src={OrangeStarSmall} />

                              <NewSubMenuLinkText
                                onClick={() =>
                                  HandleGoToResearchTools("Summarization")
                                }
                              >
                                <b>AI</b> Summarization
                              </NewSubMenuLinkText>
                            </NewSubMenuText>

                            <NewSubMenuText>
                              <Image alt="Icon" src={OrangeStarSmall} />

                              <NewSubMenuLinkText
                                onClick={() => HandleGoToResearchTools("Search")}
                              >
                                <b>AI</b> Company Search
                              </NewSubMenuLinkText>
                            </NewSubMenuText>

                            <NewSubMenuText>
                              <Image alt="Icon" src={OrangeStarSmall} />

                              <NewSubMenuLinkText
                                onClick={() =>
                                  HandleGoToResearchTools("Transcription")
                                }
                              >
                                <b>AI</b> Video Transcription
                              </NewSubMenuLinkText>
                            </NewSubMenuText>
                          </div>
                        </DropLeftMenu>
                      </div>
                      <div>
                        <TempTitle>
                          Jumpstart your research with ready templates that
                          support speedy research.
                        </TempTitle>
                        <TempImg>
                          <Image width={329} height={219} alt="Jumpstart your research with ready templates that support speedy research." src={SWOTIMG} />
                        </TempImg>
                        <DescTemp>
                          Jumpstart your research with ready frameworks and save
                          time!
                        </DescTemp>
                        <ViewTemp onClick={HandleResearchTemplateClick}>
                          View all templates
                        </ViewTemp>
                      </div>
                    </DrpContent>
                  </PlfrmDrpdwn>
                )}
              </div>
              {isModalOpen && (
                <Newsletter
                  setMessageForSucessBanner={props.setMessageForSucessBanner}
                  setSuccessBanner={props.setSuccessBanner}
                  mainHeading="AI Video Transcription - Early Access"
                  forEarlyAccess={true}
                  onClose={closeModal}
                />
              )}

              {/* Solution */}
              <div style={{ position: "relative" }}>
                <MenuWrp
                  className={isSolution ? "submene-active" : ""}
                  onClick={toggleSolution}
                >
                  <MenuTxt>Solutions</MenuTxt>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 -25 110 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M102.365 1.30035L54.9952 47.5257L7.62546 1.30035C6.77912 0.472792 5.64298 0.00948728 4.4599 0.00948728C3.27682 0.00948728 2.14068 0.472792 1.29434 1.30035C0.884543 1.70231 0.558984 2.18207 0.336731 2.71156C0.114478 3.24105 0 3.80961 0 4.38394C0 4.95828 0.114478 5.52684 0.336731 6.05632C0.558984 6.58581 0.884543 7.06558 1.29434 7.46753L51.6874 56.6532C52.5724 57.5167 53.7593 58 54.9952 58C56.231 58 57.4179 57.5167 58.3029 56.6532L108.696 7.47702C109.109 7.07477 109.437 6.59382 109.661 6.06258C109.885 5.53134 110 4.96056 110 4.38394C110 3.80732 109.885 3.23654 109.661 2.7053C109.437 2.17406 109.109 1.69312 108.696 1.29086C107.85 0.4633 106.713 0 105.53 0C104.347 0 103.211 0.4633 102.365 1.29086V1.30035Z"
                        fill="#606060"
                      />
                    </svg>
                  </div>
                </MenuWrp>
                {isSolution && (
                  <SolDrpdwn>
                    <Svg
                      isSolution={isSolution}
                      xmlns="http://www.w3.org/2000/svg"
                      width="78"
                      height="40"
                      viewBox="0 0 78 40"
                      fill="none"
                    >
                      <path
                        d="M39.1772 0L77.6772 40H0.677246L39.1772 0Z"
                        fill="white"
                      />
                    </Svg>
                    <SolContent>
                      <SolDrpHeader>
                        <div style={{ width: "234px" }}>
                          <SolDrpTxt>By Research Goal</SolDrpTxt>
                        </div>
                        <div style={{ width: "226px" }}>
                          <SolDrpTxt>By Team</SolDrpTxt>
                        </div>
                      </SolDrpHeader>
                      <div>
                        <ResearchWrap>
                          <WrapedTxt>
                            <HoverTxt onClick={handleMarketAnalysis}>
                              <P
                                text="Market Analysis"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"
                              ></P>
                              <P
                                text="Market Landscape, Regulation & Policy Analysis, and more"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"
                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={handleTechnologyAnalysis}>
                              <P
                                text="Technology Analysis"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"
                              ></P>
                              <P
                                text="Find Emerging Technologies, Identify Startups"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"
                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={handleNewBusinessCreation}>
                              <P
                                text="New Business Creation"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"
                              ></P>
                              <P
                                text="Identify new use cases, potential buyers and partners"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={handleNewProductDevelopment}>
                              <P
                                text="New Product Development"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="Discover Breakthrough Innovations"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={handleApplicationUseCaseAnalysis}>
                              <P
                                text="Application Use Case Analysis"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="Adjacent Market Identification & Market Creation"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                          </WrapedTxt>
                          <WrapedTxt>
                            <HoverTxt onClick={HandleCorporate}>
                              <P
                                text="Corporate and Strategy"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="Strategize for sustained growth"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={HandleRandD}>
                              <P
                                text="R&D and Technology Scouting"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="Understand niche technologies & find 10x start ups"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={HandleSales}>
                              <P
                                text="Sales and Marketing"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="Identify Target Customers"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={HandleCVC}>
                              <P
                                text="CVC and M&A"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="Identify New Investment avenues for growth"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                            <HoverTxt onClick={HandleNewBusiness}>
                              <P
                                text="New Business Development"
                                color="#000"
                                fontSize="14px"
                                fontWeight="700"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                              <P
                                text="New Markets for existing technologies"
                                color="#646464"
                                fontSize="12px"
                                fontWeight="400"
                                cursor="pointer"
                                letterSpacing="0px"

                              ></P>
                            </HoverTxt>
                          </WrapedTxt>
                        </ResearchWrap>
                      </div>
                    </SolContent>
                  </SolDrpdwn>
                )}
              </div>

              {/* Resources */}
              <div style={{ position: "relative" }}>
                <MenuWrp
                  className={gla ? "submene-active" : ""}
                  onClick={toggleVisibilityGla}
                >
                  <MenuTxt>Resources</MenuTxt>
                  <div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 -25 110 58"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M102.365 1.30035L54.9952 47.5257L7.62546 1.30035C6.77912 0.472792 5.64298 0.00948728 4.4599 0.00948728C3.27682 0.00948728 2.14068 0.472792 1.29434 1.30035C0.884543 1.70231 0.558984 2.18207 0.336731 2.71156C0.114478 3.24105 0 3.80961 0 4.38394C0 4.95828 0.114478 5.52684 0.336731 6.05632C0.558984 6.58581 0.884543 7.06558 1.29434 7.46753L51.6874 56.6532C52.5724 57.5167 53.7593 58 54.9952 58C56.231 58 57.4179 57.5167 58.3029 56.6532L108.696 7.47702C109.109 7.07477 109.437 6.59382 109.661 6.06258C109.885 5.53134 110 4.96056 110 4.38394C110 3.80732 109.885 3.23654 109.661 2.7053C109.437 2.17406 109.109 1.69312 108.696 1.29086C107.85 0.4633 106.713 0 105.53 0C104.347 0 103.211 0.4633 102.365 1.29086V1.30035Z"
                        fill="#606060"
                      />
                    </svg>
                  </div>
                </MenuWrp>
                {isGla && (
                  <ResouDrpdwn>
                    <Svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="78"
                      height="40"
                      viewBox="0 0 78 40"
                      fill="none"
                    >
                      <path
                        d="M39.1772 0L77.6772 40H0.677246L39.1772 0Z"
                        fill="white"
                      />
                    </Svg>
                    <Box>
                      <Container>
                        <Row>
                          <Col xl={4} lg={4} sm={4} xs={4}>
                            <div>
                              <GrowthTitleICS
                                onClick={HandleInteractiveCustomerStories}
                              >
                                Interactive Customer Stories
                              </GrowthTitleICS>
                            </div>
                          </Col>
                          <Col xl={4} lg={4} sm={4} xs={4}>
                            <GrowthTitle1>Latest Events</GrowthTitle1>

                            <TickTitle>
                              <Image alt="CheckIcon" src={BlueTick} />
                              <GrowthTitle2 onClick={ceseve}>
                                Ideapoke CES 2024 Reports
                              </GrowthTitle2>
                            </TickTitle>

                            <TickTitle>
                              <Image alt="CheckIcon" src={BlueTick} />
                              <GrowthTitle2 onClick={ceseves}>
                                Ideapoke SXSW 2024 Report
                              </GrowthTitle2>
                            </TickTitle>

                            <TickTitle>
                              <Image alt="CheckIcon" src={BlueTick} />
                              <GrowthTitle2 onClick={cersac}>
                                Ideapoke RSAC 2024 Report
                              </GrowthTitle2>
                            </TickTitle>
                          </Col>
                          <Col xl={4} lg={4} sm={4} xs={4}>
                            <FixedWidthBox>
                              <GrowthTitle1>Growth Leader Academy</GrowthTitle1>

                              <TickTitle>
                                <Image alt="CheckIcon" src={BlueTick} />
                                <GrowthTitle2 onClick={HandleReports}>
                                  Reports
                                </GrowthTitle2>
                              </TickTitle>

                              <TickTitle>
                                <Image alt="CheckIcon" src={BlueTick} />
                                <GrowthTitle2 onClick={HandleWhitePaper}>
                                  Whitepapers
                                </GrowthTitle2>
                              </TickTitle>

                              <TickTitle>
                                <Image alt="CheckIcon" src={BlueTick} />
                                <GrowthTitle2 onClick={HandleCaseStudy}>
                                  Case Studies
                                </GrowthTitle2>
                              </TickTitle>

                              <TickTitle>
                                <Image alt="CheckIcon" src={BlueTick} />
                                <GrowthTitle2 onClick={HandleArticle}>
                                  Articles
                                </GrowthTitle2>
                              </TickTitle>
                            </FixedWidthBox>
                          </Col>
                        </Row>
                      </Container>
                    </Box>


                  </ResouDrpdwn>
                )}
              </div>

              <div>
                <MenuWrp
                  className={contactus ? "submene-active" : ""}
                  onClick={AboutUs}
                >
                  <MenuTxt>About Us</MenuTxt>
                </MenuWrp>
              </div>

              {props &&
                props.response &&
                props.response == "valid" &&
                (url.includes("/interactivestories") ||
                  url.includes("/ces-2023") ||
                  url.includes("/ces") ||
                  url.includes("/CES-Company-Profiles") ||
                  url.includes("/CES2024Reports") ||
                  url.includes("/viewcesreport")) ? (
                <div>
                  <TrialBtn onClick={HandleGoToDashboard}>
                    Go to dashboard
                  </TrialBtn>
                </div>
              ) : (
                <>
                  <ThreeBtnWrapper>
                    <ExtenedOrangeOutlineBtn
                      onClick={HandleLogin}
                      width="93px"
                      height="40px"
                    >
                      Log In
                    </ExtenedOrangeOutlineBtn>

                    <ExtenedOrangeOutlineBtn
                      onClick={ContactSales}
                      width="140px"
                      height="40px"
                    >
                      Contact Sales
                    </ExtenedOrangeOutlineBtn>

                    <ExtenedBlackBtn
                      onClick={HandleFreeTrail}
                      width="175px"
                      height="40px"
                    >
                      Get Started for free
                    </ExtenedBlackBtn>
                  </ThreeBtnWrapper>
                </>
              )}
            </HeaderOption>

            {/* Responsive Header */}
            <nav role="navigation" className="rdt-menu-e rdt-menu-1-e">
              <label htmlFor="rdt-menu-e" className="mobile-menu-fix">
                <Image width={18} height={21} priority={true} src={BarIcon} alt="BarIcon" />
              </label>
              <input type="checkbox" id="rdt-menu-e"></input>
              <ul className="rdt-icon menu-list-fix">
                <li className="rdt-menu-hasdropdown-e">
                  <a href="#">
                    Product
                    <label title="toggle rdt-menu-e" htmlFor="about">

                      <Image className="add-icon" alt="Add" src={Add} />
                    </label>
                  </a>
                  <input type="checkbox" id="about"></input>
                  <ul className="rdt-menu-dropdown-e">

                    <li
                      onClick={HandleResearchDesignPlatform}
                      className="rdt-menu-fix-e"
                    >
                      Ideapoke Business Research Platform
                    </li>
                    <li
                      onClick={HandleResearchAssistantClick}
                      className="rdt-menu-fix-e"
                    >
                      Research Assistant
                    </li>
                    <li
                      onClick={HandleResearchEliteClick}
                      className="rdt-menu-fix-e"
                    >
                      Research Elite
                    </li>
                    <li
                      onClick={HandleResearchAIToolsClick}
                      className="rdt-menu-fix-e"
                    >
                      <b>AI</b> Research Tools
                    </li>
                    <li
                      onClick={() => HandleGoToResearchTools("Summarization")}
                      className="rdt-menu-fix-e"
                    >
                      <b>AI</b> Summarization
                    </li>
                    <li
                      onClick={() => HandleGoToResearchTools("Search")}
                      className="rdt-menu-fix-e"
                    >
                      <b>AI</b> Company Search
                    </li>
                    <li
                      onClick={() => HandleGoToResearchTools("Transcription")}
                      className="rdt-menu-fix-e"
                    >
                      <b>AI</b> Video Transcription
                    </li>
                    <li
                      onClick={HandleResearchTemplateClick}
                      className="rdt-menu-fix-e"
                    >
                      View all templates
                    </li>
                  </ul>
                </li>
                <li className="menu-hasdropdown-e">
                  <a href="#">
                    Solutions
                    <label title="toggle rdt-menu-e" htmlFor="platform">
                      {/* <i class="fa fa-caret-down"></i> */}
                      <Image className="add-icon" alt="Add" src={Add} />
                    </label>
                  </a>
                  <input type="checkbox" id="platform"></input>
                  <ul className="rdt-menu-dropdown-e menu-scroll-e" id="sl-style-1">
                    <div className="rdt-fix-1-e">By Research Goal</div>
                    <li onClick={handleMarketAnalysis} className="rdt-menu-fix-e">
                      Market Analysis
                    </li>
                    <li
                      onClick={handleTechnologyAnalysis}
                      className="rdt-menu-fix-e"
                    >
                      Technology Analysis
                    </li>
                    <li
                      onClick={handleNewBusinessCreation}
                      className="rdt-menu-fix-e"
                    >
                      New Business Creation
                    </li>
                    <li
                      onClick={handleNewProductDevelopment}
                      className="rdt-menu-fix-e"
                    >
                      New Product Development
                    </li>
                    <li
                      onClick={handleApplicationUseCaseAnalysis}
                      className="rdt-menu-fix-e"
                    >
                      Application Use Case Analysis
                    </li>
                    <div className="rdt-fix-1-e"> By Team</div>
                    <li onClick={HandleCorporate} className="rdt-menu-fix-e">
                      Corporate and Strategy
                    </li>
                    <li onClick={HandleRandD} className="rdt-menu-fix-e">
                      R&D and Technology Scouting
                    </li>
                    <li onClick={HandleSales} className="rdt-menu-fix-e">
                      Sales and Marketing
                    </li>
                    <li onClick={HandleCVC} className="rdt-menu-fix-e">
                      CVC and M&A
                    </li>
                    <li onClick={HandleNewBusiness} className="rdt-menu-fix-e">
                      New Business Development
                    </li>
                  </ul>
                </li>
                <li className="rdt-menu-hasdropdown-e">
                  <a href="#">
                    Resources
                    <label title="toggle rdt-menu-e" htmlFor="Resources">
                      <Image className="add-icon" alt="Add" src={Add} />
                    </label>
                  </a>
                  <input type="checkbox" id="Resources"></input>

                  <ul className="rdt-menu-dropdown-e">
                    <li
                      onClick={HandleInteractiveCustomerStories}
                      className="rdt-menu-fix-e"
                    >
                      Interactive Customer Stories
                    </li>
                    <li className="rdt-menu-fix-e">
                      <strong>Latest Events</strong>
                    </li>

                    <li onClick={ceseve} className="rdt-menu-fix-e">
                      Ideapoke CES 2024 Reports
                    </li>
                    <li onClick={ceseves} className="rdt-menu-fix-e">
                      Ideapoke SXSW 2024 Report
                    </li>
                    <li onClick={cersac} className="rdt-menu-fix-e">
                      Ideapoke RSAC 2024 Report
                    </li>

                    <li className="rdt-menu-fix-e">
                      <strong>Growth Leader Academy</strong>
                    </li>
                    <li onClick={HandleReports} className="rdt-menu-fix-e">
                      Reports
                    </li>
                    <li onClick={HandleWhitePaper} className="rdt-menu-fix-e">
                      Whitepapers
                    </li>
                    <li onClick={HandleCaseStudy} className="rdt-menu-fix-e">
                      Case Studies
                    </li>
                    <li onClick={HandleArticle} className="rdt-menu-fix-e">
                      Articles
                    </li>
                  </ul>
                </li>
                <li className="rdt-p-e" onClick={AboutUs}>
                  About Us
                </li>
                <li className="rdt-p-e" onClick={HandleLogin}>
                  Log In
                </li>
                <li className="rdt-p-e" onClick={ContactSales}>
                  Contact Sales
                </li>
                <li onClick={HandleFreeTrail} className="rdt-p-e">
                  Get Started for free
                </li>
              </ul>
            </nav>
          </div>
        </MenuWrap>
      </RobotoFontWrapper>
    </div>
  );
}

//Library Imports
import styled from "styled-components";

// CSS Imports
import "./global.css";

// CSS-in-JS
// export const BlackBtn = styled.button`
//   font-family: Roboto;
//   width: ${({ width }) => width || "241px"};
//   height: ${({ height }) => height || "56px"};
//   border-radius: 100px;
//   background-color: var(--bg-color-raisin-black);
//   color: var(--text-color-white);
//   font-size: var(--size20);
//   font-weight: var(--fw400);
//   line-height: var(--lh23);
//   text-align: center;
//   outline: none;
//   cursor: pointer;
//   border: 1px solid #242424;
//   transition: background-color 0.3s ease, border 0.3s ease, color 0.3s ease;

//   &:hover {
//     background-color: var(--bg-color-outer-space);
//     border: 1px solid #595959;
//     color: var(--text-color-pumpkin);
//   }
//     @media (min-width: 576px) and (max-width: 1400px) {
//    margin-left:40px;
//   }
// `;

export const BlackBtn = styled.button`
  font-family: Roboto;
  width: ${({ width }) => width || "241px"};
  height: ${({ height }) => height || "56px"};
  border-radius: 100px;
  background-color: var(--bg-color-raisin-black);
  color: var(--text-color-white);
  font-size: var(--size20);
  font-weight: var(--fw400);
  line-height: var(--lh23);
  text-align: center;
  outline: none;
  cursor: pointer;
  border: 1px solid #242424;
  transition: background-color 0.3s ease, border 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: var(--bg-color-outer-space);
    border: 1px solid #595959;
    color: var(--text-color-pumpkin);
  }
`;
